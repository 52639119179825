.sd-app-navbar {
  color: $sd-light-gray-blue;
  background-color: $sd-dark-blue;

  .#{$ns}-navbar-group.#{$ns}-align-left .#{$ns}-button {
    margin-left: 3px;
    margin-right: 15px;
  }

  &__logo {
    margin-right: 10px;
    cursor: pointer;

    img {
      height: 45px;
      margin-top: 4px;
      user-select: none;
    }
  }
}

#nav-help-menu,
#nav-employee-home {
  color: $white;
}

.sd-dialog-connection {
  width: 400px;

  .#{$ns}-dialog-body {
    margin: 20px 15px;
  }

  .sd-connection-form__body {
    min-height: 300px;
  }

  &__tooltip {
    max-width: 200px;
  }
}

.sd-copy-to-clipboard-button {
  font-family: 'Proxima Nova', 'Montserrat', Helvetica, sans-serif;
}

.sd-sign-in {
  &__title {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    text-align: center;

    .#{$ns}-text-large {
      font-size: 1.125em;
      margin-bottom: 6px;
    }
  }
}

.sd-delete-connection {
  &__title {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    text-align: center;

    .#{$ns}-text-large {
      font-size: 1.125em;
      margin-bottom: 6px;
    }
  }
}

.product-description {
  font-weight: 400;
  font-size: 1.125em;
  margin-bottom: 0px;
}

.connection-card {
  h3.#{$ns}-heading {
    display: flex;
  }

  .connection-menu {
    display: flex;
    margin-left: auto;
    justify-content: flex-end;
  }
}

.connection-menu-item {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.controls {
  display: flex;
  flex-direction: column;
  align-items: center;

  .cta-button {
    background-color: $sd-orange;
    background-image: none;
    box-shadow: none;
    font-weight: bold;
    color: $white;
  }

  .cta-button:hover,
  .cta-button:active {
    background-color: $sd-orange;
    background-image: none;
    box-shadow: inset(0, 0, 0, 5px, rgba(16, 22, 26, 0.3)),
      inset(0, -5px, 0, rgba(16, 22, 26, 0.3));
    color: $white;
    font-weight: bold;
  }

  p {
    font-weight: bold;
    font-size: 105%;
    margin: 10px;
  }

  button + button {
    margin-top: 5px;
  }

  .oauth {
    cursor: pointer;
    height: 40px;
    width: 195px;
    justify-content: left;
    img {
      height: 40px;
      padding: 10px 10px 10px 0px;
    }
    svg {
      padding: 10px 10px 10px 0px;
      fill: $sd-dark-gray;
    }
  }
}

.controls-wide {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.connect-dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 280px;
  min-width: 300px;
  padding-top: 10px;
  box-shadow: 0 30px 40px rgba(0, 0, 0, 0.2);
  background-color: #fefefe;

  .heading {
    font-weight: bold;
    text-align: center;
    color: $sd-dark-gray-blue;
    font-size: 2em;
    font-weight: 700;
    line-height: 1.2em;
    letter-spacing: -0.015em;
  }
}

.connect-text {
  font-weight: 400;
  font-size: 1em;
  color: $sd-dark-gray;
  line-height: 1.2em;
  letter-spacing: -0.015em;
}

.sd-dialog-cookie-consent {
  max-width: 50%;
  min-width: 700px;

  &__details {
    margin-top: 20px;
  }

  .sd-advanced-options a,
  .sd-advanced-options a:hover {
    color: $pt-text-color;
    text-decoration: none;
  }

  &__save {
    display: flex;
    justify-content: flex-end;
  }
}

.sd-advanced-options {
  &__button {
    display: flex;
    justify-content: space-between;
  }

  .#{$ns}-collapse .#{$ns}-collapse-body {
    padding: 5px 10px;
  }
}

.sd-dialog-add-new {
  max-width: 50%;
  min-width: 650px;
  min-height: 350px;
  padding: 0;
}

.sd-dialog-email-verification {
  text-align: center;
  background: white;
  &__header {
    display: flex;
    justify-content: end;
    padding: 8px;
  }
  &__body {
    &__title {
      font-size: 1.5rem;
      font-weight: bold;
      padding: 1.5rem 0;
    }
    &__button-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
      place-items: end center;
      padding: 8px 0;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    line-height: 1.2rem;
    &__resend-email-button {
      background: none;
      color: $pt-link-color;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
    }
    &__resend-email-button:hover {
      text-decoration: underline;
    }
  }
}

.sd-radio-select {
  &__container {
    margin: 10px 0;
  }

  &__input {
    opacity: 0;
    position: fixed;
    width: 0;

    &:checked + &-label {
      border-color: $sd-medium-blue;
      color: $sd-medium-blue;
    }
  }

  &__input-label {
    display: inline-block;
    background-color: $white;
    border: 2px solid $white;
    padding: 10px 20px;
    border-radius: 4px;
    width: 100%;

    &:hover {
      border-color: $sd-medium-blue;
    }

    &__icon {
      padding-right: 10px;
    }
  }
}

.sd-dialog-profile {
  min-width: 600px;
  color: $black;

  form {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  &__body {
    padding: 15px 30px;
    display: flex;
    flex-direction: column;
  }

  &__text-input {
    input {
      box-shadow: none;
    }
  }

  &__name {
    &__container {
      display: flex;
      column-gap: 20px;
    }
    &__form-group {
      width: 100%;
    }
  }

  &__step-indicator {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 20px;
    align-items: center;

    &__container {
      text-align: center;
      min-width: 100px;
      z-index: 3;
      color: $sd-dark-gray;
    }

    &__circle {
      background: $sd-dark-gray;
      display: block;
      height: 20px;
      margin: 0 auto 10px;
      width: 20px;
      border-radius: 50%;
      z-index: 3;
    }

    &__active-text {
      color: $black;
      font-weight: bold;
    }

    &__active-circle {
      // circle color
      background: $sd-medium-blue;
      // circle border
      box-shadow: 0 0 0 2px $black;
      // space between circle and border - same color as background
      border: 3px solid $light-gray4;
    }

    &__line {
      min-width: 120px;
      height: 5px;
      z-index: 2;
      margin-left: -40px;
      margin-right: -40px;
      margin-bottom: 25px;
      border-top: 1px dashed $sd-dark-gray;
    }
  }

  &__selector {
    &-container {
      margin-bottom: 15px;
    }
    &__button {
      display: flex;
      justify-content: space-between;
      width: 100%;

      // need to override blueprint :(
      background-color: $white !important;
    }
    &__popover-target {
      width: 100%;
    }
  }

  &__button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    column-gap: 30px;
    margin: 25px 0px 0px 0px;
  }

  &__validation-callout {
    margin-top: 15px;
  }
}

.sd-dialog-create-api-token,
.sd-dialog-create-voicebox-app,
.sd-dialog-update-voicebox-app {
  .#{$ns}-dialog-body {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

.sd-dialog-create-api-token {
  .sd-create-api-token-secret {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;
  }
}

.sd-dialog-create-voicebox-app {
  min-height: 520px;
  max-height: calc(100vh - 200px);
  overflow: auto;

  .#{$ns}-form-group.#{$ns}-inline {
    justify-content: space-between;
    align-items: center;
  }

  .sd-create-voicebox-app-connections {
    max-height: 30vh;
    overflow: auto;
  }

  .sd-create-voicebox-app-connections,
  .sd-create-voicebox-app-connections-empty {
    margin-top: 5px;
  }

  .sd-create-voicebox-app-connection-card {
    display: flex;
    margin-bottom: 10px;
    padding: 10px 12px 9px;

    .#{$ns}-icon {
      align-self: center;
      margin: 10px;
      margin-right: 20px;
    }
  }

  .sd-selector {
    .sd-selector-button {
      min-width: 200px;
      max-width: 300px;
      flex: 1 0 auto;

      .#{$ns}-button-text {
        flex: 1;
        text-align: start;
      }
    }
  }
}

.sd-portal-navbar,
.sd-voicebox-page-sidebar {
  border-right: 1px solid $pt-divider-black;
}

.sd-voicebox-drawer {
  .#{$ns}-drawer-header {
    height: 51px;
  }
}

.sd-voicebox-page {
  flex: 1;
  display: flex;
  height: 100%;

  .sd-voicebox-page-sidebar {
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    min-width: 200px;
    width: 300px;
    height: 100%;
    overflow: hidden;

    .sd-voicebox-page-sidebar-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      background: $light-gray5;
      padding: 6px 8px;
    }

    .sd-voicebox-page-sidebar-actions {
      display: flex;
      gap: 10px;
    }

    .sd-voicebox-page-sidebar-actions-new {
      flex: none;
      border-radius: 30px;
      padding-left: 6px;

      .#{$ns}-icon {
        margin-right: 4px;
      }

      .#{$ns}-icon:first-child:last-child {
        margin-right: -10px;
      }
    }

    .sd-voicebox-page-sidebar-body {
      overflow-x: hidden;
      overflow-y: auto;

      &__row {
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-grow: 1;
        color: $sd-dark-gray-blue;

        .#{$ns}-editable-text-input::placeholder {
          color: $sd-dark-gray;
        }

        &__item {
          width: 100%;

          &--hover {
            width: calc(100% - 30px);
          }
        }

        .#{$ns}-editable-text {
          width: 100%;
          padding: 2px 4px;
          margin: 4px 6px;
        }

        .#{$ns}-menu-item {
          padding: 5px 10px;
          width: 100%;

          &.#{$ns}-selected {
            background-color: $sd-portal-navbar-selected;
            color: $pt-heading-color;
          }
        }
      }
    }
  }

  .sd-voicebox-page-sidebar {
    background: rgba($light-gray5, 0.5);
    .sd-voicebox-page-sidebar-body .#{$ns}-menu {
      background: none;
    }
  }

  .sd-voicebox-page-sidebar {
    transition: width 200ms, min-width 200ms;
    &--collapsed {
      min-width: 20px;
      width: 20px;
    }
  }

  .sd-voicebox-page-collapse {
    position: relative;
    width: 30px;
    height: 30px;
    top: 50px;
    right: 15px;
    border-radius: 15px;
  }

  .sd-voicebox-page-body {
    flex: 1;
    min-width: 500px;
    height: 100%;
  }

  .sd-voicebox-drawer {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .#{$ns}-drawer-header {
    display: none;
  }

  .#{$ns}-drawer-body {
    .sd-voicebox-empty,
    .sd-voicebox-messages {
      padding-left: max(30px, calc(50% - max(25vw, 425px)));
      padding-right: max(30px, calc(50% - max(25vw, 425px)));
    }
  }

  .#{$ns}-drawer-footer {
    padding-left: max(30px, calc(50% - max(25vw, 425px)));
    padding-right: max(30px, calc(50% - max(25vw, 425px)));
  }

  .sd-voicebox-query-builder {
    .sd-query-builder-top-expression {
      overflow-x: auto;
      margin-bottom: 20px;
      background-color: rgba($gray3, 0.15);
      border-radius: $pt-border-radius;

      .sd-query-builder-sub-expression {
        margin-bottom: 0;
        padding-bottom: 0;
        background-color: transparent;
      }
    }
  }
}

.inline-link {
  display: inline-flex;
  align-items: center;
  column-gap: 4px;
}

.link-style {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: $pt-link-color;
  display: inline;
  margin: 0;
  padding: 0;
  font-weight: inherit;
  &:hover {
    text-decoration: underline;
  }
}
