.welcome-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 80px 20px 50px;
  min-height: 160px;
  max-height: 210px;
  background-color: $sd-vdark-blue;
  background-image: url('../../templates/images/outro-bg.svg');
  background-position-y: top;
  background-position-x: right;
  background-repeat: no-repeat;

  h2 {
    color: $white;
    line-height: 1.2em;
    letter-spacing: -0.015em;
    font-weight: bolder;
    font-size: 2.5em;
    margin: 0px;
  }

  p {
    color: $white;
    line-height: 1.2em;
    letter-spacing: -0.015em;
    font-weight: 500;
    font-size: 1.25em;
    margin: 0px;

    a,
    button {
      color: $sd-orange;
      font-weight: bold;
    }
  }
}

.welcome-container-voicebox {
  background-image: url('../../templates/images/home-voicebox.png');
  background-size: contain;
}

.sd-landing-dashboard {
  padding: 20px 50px;

  .sd-landing-header {
    padding: 15px 0px;

    .header-buttons {
      display: flex;
      column-gap: 15px;
    }
  }

  .dashboard {
    padding: 15px 0px;
  }
}

.sd-landing-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 100px;
  column-gap: 50px;
  margin: 0 5%;
  padding: 20px 0;
}

.sd-landing-full-width {
  grid-column-start: 1;
  grid-column-end: 5;
  flex-direction: column;
}

.sd-landing-footer-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  &.footer-links-disabled {
    display: none;
  }
}

.marketplace-disabled {
  &.sd-landing-grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .sd-landing-full-width {
    grid-column-end: 4;
  }

  .sd-landing-marketplace {
    display: none;
  }
}

.sd-landing-grid-cell {
  display: flex;
  justify-content: left;
  align-content: left;
}

.content-list-container {
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 100%;
}

.content-list-header {
  margin-bottom: 0px;
}

.content-list-divider {
  width: 100%;
}

.content-list {
  padding-inline-start: 0;
  margin-block-start: 0;
  list-style: none;
  list-style-position: outside;
}

.content-list-item {
  font-size: 115%;
  display: flex;
  flex-direction: column;
  color: #638f9c;
}

.sd-landing-new-user {
  display: flex;
  flex-direction: column;
  padding: 50px;

  .kits-section {
    padding: 20px 0px;
  }

  .kits-link-row {
    text-align: right;
    padding-bottom: 10px;
  }

  &-actions {
    display: flex;
    flex-direction: column;

    .divider {
      margin-top: 20px;
      height: 100%;
      width: 0;
      border-left: 1px solid $light-gray1;
    }

    .new-connection-section {
      display: flex;
      flex-direction: row;
      gap: 10px;
      padding: 20px 0px;
    }
  }
}
