$medium-screen-size: 900px;

.user-dashboard {
  display: flex;
  margin-top: 50px;
  margin-left: 5%;
  margin-right: 5%;
  padding-bottom: 75px;
  flex-direction: column;
  line-height: 1.5;
  color: black;
  height: 100%;
}

.button-bar {
  margin-bottom: 25px;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 50px;
  row-gap: 50px;
}

.user-info-panel {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid lightgray;

  @media (max-width: $medium-screen-size) {
    grid-column-start: 1;
    grid-column-end: 4;
  }
}

.details-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-auto-rows: min-content;
  row-gap: 1rem;

  .#{$ns}-checkbox {
    display: inline;
  }
}

.date-time {
  display: flex;
  flex-direction: column;
}

.key {
  font-weight: bold;
}

.key::after {
  content: ':';
}

.connection-cloud-panel {
  border: 1px solid lightgray;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-start: 2;
  grid-column-end: 4;
  padding: 20px;
  overflow: auto;

  @media (max-width: $medium-screen-size) {
    grid-column-start: 1;
    grid-column-end: 4;
  }
}

.cloud-details-panel {
  border: 1px solid lightgray;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-start: 1;
  grid-column-end: 4;
  padding: 20px;
  column-gap: 20px;
}

.cloud-details-header {
  grid-column-start: 1;
  grid-column-end: 4;
}

hr {
  width: 100%;
  margin-bottom: 25px;
  border: none;
  border-top: 1px solid lightgray;
}

.error-page {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.error-content-wrapper {
  width: fit-content;
}
