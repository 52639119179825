.sd-diagnostic {
  padding: 20px;
  overflow-x: auto;
  width: 100%;
  table {
    padding-bottom: 15px;
    overflow: auto;
    tr td:first-child {
      width: 300px;
    }
  }
  .retry {
    float: right;
  }

  .#{$ns}-card {
    overflow-x: auto;
  }
}

#diagnostic-report {
  display: flex;
  column-gap: 20px;
}

.diagnostic-check {
  display: flex;
  column-gap: 10px;
  padding-bottom: 10px;
  width: 450px;
}
.diagnostic-message {
  display: flex;
  column-gap: 10px;
  padding: 20px 0;
  width: 100%;
}
